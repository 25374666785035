import React, { useState, useEffect, useCallback } from 'react';
import uuidv4 from 'uuid/v4';

//HOC
import Layout from '../../../layouts/subpage_layout';
import BreadcrumbsLayout from '../../../layouts/breadcrumbs_layout';

//CSS
import {
    Grid,
    TextField,
    Button,
    Snackbar
} from '@material-ui/core';

import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import AddIcon from '@material-ui/icons/Add';
import notifyStyles from './bejelentes.module.scss';

//COMPONENTS
import HelpDialogSlide from '../../helpDialog';
import ErrorSnackbar from '../../ErrorSnackbar/ErrorSnackbar';
import Head from '../../head';

//GATSBY
import { graphql, useStaticQuery } from 'gatsby';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';

const SEND_DOCUMENT = gql`
    mutation($userName: String!, $body: JSON!){
    sendDocumentForm(data: {senderUserName: $userName, body: $body}){
        id
        body
        }
    }
`;

const Bejelentes = props => {

    const data = useStaticQuery(graphql`
    query{
    markdownRemark(frontmatter: {title: {eq: "Bejelentés"}}){
        frontmatter{
        title
        mainpage
        }
        html
    }
    allCountryMappingCsv{
      nodes{
        key
        hun
      }
    }
    allLanguageMappingCsv{
      nodes{
        key
        hun
      }
    }
    allOdrKonyvtarkodListaCsv(sort: {fields: name, order: ASC}){
      nodes{
        name
        code
        }
      }
    }
`)

    const [sendDocument] = useMutation(SEND_DOCUMENT);



    const [formData, setFormData] = useState(
        [
            {
                name: 'Dokumentum típusa',
                helps: [],
                inputFields: [
                    {
                        name: '* Dokumentum típusa',
                        size: 12,
                        type: 'select',
                        inputAttrs: {
                            className: 'selectField',
                            margin: "none",
                            variant: "outlined",
                            name: '1',
                            placeholder: 'Válasszon',
                        },
                        options: [
                            {
                                label: 'Válasszon',
                                value: '',
                            },
                            {
                                label: 'nyelvi anyag',
                                value: 'nyelvi anyag',
                            },
                            {
                                label: 'lejegyzett zene',
                                value: 'lejegyzett zene',
                            },
                            {
                                label: 'kéziratos zene',
                                value: 'kéziratos zene',
                            },
                            {
                                label: 'kartográfiai anyag',
                                value: 'kartográfiai anyag',
                            },
                            {
                                label: 'kéziratos kartográfiai anyag',
                                value: 'kéziratos kartográfiai anyag',
                            },
                            {
                                label: 'kivetített médium',
                                value: 'kivetített médium',
                            },
                            {
                                label: 'nem zenei hangfelvétel',
                                value: 'nem zenei hangfelvétel',
                            },
                            {
                                label: 'zenei hangfelvétel',
                                value: 'zenei hangfelvétel',
                            },
                            {
                                label: 'kétdimenziós nem kivetíthető grafika',
                                value: 'kétdimenziós nem kivetíthető grafika',
                            },
                            {
                                label: 'számítógép fájl',
                                value: 'számítógép fájl',
                            },
                            {
                                label: 'készlet',
                                value: 'készlet',
                            },
                            {
                                label: 'vegyes anyag',
                                value: 'vegyes anyag',
                            },
                            {
                                label: 'háromdimenziós alkotások vagy természeti tárgyak',
                                value: 'háromdimenziós alkotások vagy természeti tárgyak',
                            },
                            {
                                label: 'kéziratos nyelvi anyag',
                                value: 'kéziratos nyelvi anyag',
                            }
                        ]

                    }
                ]
            },
            {
                name: 'Kiemelt alapadatok',
                helps: [
                    {
                        name: 'kiadás éve',
                        value: 'a kiadási évet ebben a mezőben <b>négy számjeggyel</b> kell jelezni'
                    },
                    {
                        name: 'országkód',
                        value: 'a kiadó ország szabványos kódja (listából)'
                    },
                    {
                        name: 'nyelvi kód',
                        value: 'a kiadvány (fő részének) szabványos nyelvi kódja több nyelvű kiadvány esetén: <b>mul</b>'
                    }
                ],
                inputFields: [
                    {
                        name: 'Kiadás éve',
                        size: 4,
                        type: 'number',
                        inputAttrs: {
                            margin: "none",
                            variant: "outlined",
                            className: 'textField',
                            placeholder: "[ÉÉÉÉ]",
                            name: '2',
                        }
                    },
                    {
                        name: 'Kiadó ország kódja',
                        size: 4,
                        type: 'select',
                        inputAttrs: {
                            margin: "none",
                            variant: "outlined",
                            className: 'selectField',
                            name: '3',
                            placeholder: 'Válasszon'
                        },
                        options: []

                    },
                    {
                        name: 'Kiadás nyelvi kódja',
                        tagNumber: '041',
                        subfieldCode: 'a',
                        size: 4,
                        type: 'select',
                        inputAttrs: {
                            name: '4',
                            margin: "none",
                            variant: "outlined",
                            className: 'selectField',
                            placeholder: 'Válasszon'
                        },
                        options: [],

                    }
                ]
            },
            {
                helps: [],
                inputFields: [
                    {
                        name: 'Bibliográfiai szint',
                        size: 12,
                        type: 'select',
                        inputAttrs: {
                            name: '100',
                            className: 'selectField',
                            margin: "none",
                            variant: "outlined",
                            placeholder: 'Válasszon'
                        },
                        options: [
                            {
                                label: 'Válasszon',
                                value: '',
                            },
                            {
                                value: 'a',
                                label: 'monografikus részdokumentum (monográfia része)'
                            },
                            {
                                value: 'b',
                                label: 'periodikus jellegű részdokumentum (analitikus, periodika része)'
                            },
                            {
                                value: 'c',
                                label: 'gyűjtemény (pl. konferencia csomag)'
                            },
                            {
                                value: 'd',
                                label: 'alárendelt egység (kötet, részegység)'
                            },
                            {
                                value: 'i',
                                label: 'integrálódó forrás (cserelapos kiadvány, weboldal)'
                            },
                            {
                                value: 'm',
                                label: 'monográfia/tétel'
                            },
                            {
                                value: 's',
                                label: 'időszaki'
                            }
                        ]
                    }
                ]
            },
            {
                name: 'Katalogizáló forrás',
                helps: [
                    {
                        value: 'Kötelezően kitöltendő'
                    },
                    {
                        name: 'Szabály',
                        value: 'a feldolgozást végző könyvtár kódja (listából)'
                    },
                    {
                        name: 'Példák',
                        value: 'Sz1</br>D8</br>B419'
                    }
                ],
                inputFields: [
                    {
                        name: '* Az eredeti katalogizálást végző intézmény kódja',
                        tagNumber: '040',
                        subfieldCode: 'a',
                        size: 12,
                        type: 'select',
                        inputAttrs: {
                            name: '5',
                            className: 'selectField',
                            margin: "none",
                            variant: "outlined",
                            placeholder: 'Válasszon'
                        },
                        options: []
                    }
                ]
            },
            {
                name: 'Szerző',
                helps: [
                    {
                        name: 'Szabályok',
                        value: 'első / főszerző</br>További szerző(ke)t új mezőben (További személynév) kell felvenni</br>a szerzők nevét egységesített névalakban kell megadni: <ul><li>lehetőleg nemzeti névalak</li><li>vezetéknév, keresztnév keresztnév</li></ul></br>a szerzőségi funkciót (szövegkiadó, fordító, szerkesztő, praesens, respondens  stb.) nem kell megadni</br>háromnál több szerzőt nem szükséges feltüntetni'
                    },
                    {
                        name: 'Példák',
                        value: 'Luther, Martin</br>Augustinus, Sanctus</br>István, Magyarország : király, I., Szent</br>Pius, pápa, II.'
                    }
                ],
                inputFields: [
                    {
                        name: 'Szerző neve egységesített névalakban',
                        tagNumber: '100',
                        subfieldCode: 'a',
                        size: 12,
                        type: 'text',
                        inputAttrs: {
                            name: '6',
                            className: 'textField',
                            margin: "none",
                            variant: "outlined",
                        },
                    },
                    {
                        name: 'Névhez kapcsolódó titulusok, egyéb szavak',
                        tagNumber: '100',
                        subfieldCode: 'c',
                        size: 12,
                        type: 'text',
                        inputAttrs: {
                            name: '7',
                            className: 'textField',
                            margin: "none",
                            variant: "outlined",
                        },
                    },
                    {
                        name: 'Személynév számeleme',
                        tagNumber: '100',
                        subfieldCode: 'b',
                        size: 12,
                        type: 'text',
                        inputAttrs: {
                            name: '8',
                            className: 'textField',
                            margin: "none",
                            variant: "outlined",
                        },
                    },
                    {
                        name: 'Életrajzi, uralkodó adatok',
                        tagNumber: '100',
                        subfieldCode: 'd',
                        size: 12,
                        type: 'text',
                        inputAttrs: {
                            name: '9',
                            className: 'textField',
                            margin: "none",
                            variant: "outlined",
                        },
                    }
                ]
            },
            {
                name: 'További személyek',
                helps: [
                    {
                        name: 'Szabályok',
                        value: 'a mű létrehozásában résztvevő további személy (szerző, fordító, nyomdász, kiadó)</br></br>a példánnyal kapcsolatba hozható személy (tulajdonos)</br></br>a szeméyneveket egységesített névalakban kell megadni:<ul><li>lehetőleg nemzeti névalak</li><li>vezetéknév, keresztnév keresztnév</li></ul></br></br><ul><li>a szerzőségi funkciót (szövegkiadó, fordító stb.) nem kell megadni,</li><li>az egyéb kapcsolatot (possessor, typographus) zárójelben fel lehet tüntetni</li></ul></br></br>háromnál több szerzőt(szerkesztőt, fordítót stb.) nem szükséges feltüntetni'
                    },
                    {
                        name: 'Példák',
                        value: 'Luther, Martin</br></br>Augustinus, Sanctus</br></br>Kalckhoven, Jost (typographia)</br></br>Nagy, Matthias (possessor) '
                    }
                ],
                repeatable: true,
                inputFields: [
                    {
                        name: 'Személy neve egységesített névalakban',
                        tagNumber: '700',
                        subfieldCode: 'a',
                        size: 12,
                        type: 'text',
                        inputAttrs: {
                            name: '10',
                            className: 'textField',
                            margin: "none",
                            variant: "outlined",
                        },
                    },
                    {
                        name: 'Névhez kapcsolódó titulusok, egyéb szavak',
                        tagNumber: '700',
                        subfieldCode: 'c',
                        size: 12,
                        type: 'text',
                        inputAttrs: {
                            name: '11',
                            className: 'textField',
                            margin: "none",
                            variant: "outlined",
                        },
                    },
                    {
                        name: 'Személynév számeleme',
                        tagNumber: '700',
                        subfieldCode: 'b',
                        size: 12,
                        type: 'text',
                        inputAttrs: {
                            name: '12',
                            className: 'textField',
                            margin: "none",
                            variant: "outlined",
                        },
                    },
                    {
                        name: 'Életrajzi, uralkodó adatok',
                        tagNumber: '700',
                        subfieldCode: 'd',
                        size: 12,
                        type: 'text',
                        inputAttrs: {
                            name: '13',
                            className: 'textField',
                            margin: "none",
                            variant: "outlined",
                        },
                    }
                ]
            },
            {
                name: 'Cím',
                helps: [
                    {
                        name: 'Szabálok',
                        value: 'Címlapon szereplő cím betűhíven mező kötelezően kitöltendő<br/><br/>Szabályok	Főcím : Alcím = Párhuzamos cím : Párhuzamos alcím / szerzőségi adat<br/><br/>a cím-, és szerzőségi adatokat a címlap alapján, betűhíven kell rögzíteni  (kivétel: a folyamatos verzál szedés)<br/><br/>a címben előforduló számokat a címben szereplő formában kell leírni<br/><br>a műhöz kapcsolódó egységesített, variáns, vagy sorozati címeket az azoknak megfelelő további mezőkben kell leírni'
                    },
                    {
                        name: 'Példák',
                        value: "Thesauros tes ellenikos glosses = Thesaurus linguae Gaecae a Henrico Stephano constructus<br/><br/>Elementa rei rusticae in usum academiarum Regni Hungariae conscripta a Ludovico Mitterpacher, de Mitternburg<br/><br/>Veritates christianae, quae modum exhibent bene vivendi, et bene moriendi Authore R. P. Henrico Balde<br/><br/>Iacobi Marchantii vitis florigera : in qua doctrina et veritas evangelica continetur [...] Accedunt opuscula pastoralia Authore [...] Jacobo Marchantio <br/><br/>A latin nyelv ékes szókötése / A honi ifjuságnak segédkönyvűl írta Vass József <br/><br/>Yung' Éjtzakáji és egyéb munkáji, mellyek magyar nyelvre fordíttattak Pétzeli Jó'sef ... által"
                    }
                ],
                inputFields: [
                    {
                        name: '* Címlapon szereplő cím betűhíven',
                        tagNumber: '245',
                        subfieldCode: 'a',
                        size: 12,
                        type: 'text',
                        inputAttrs: {
                            name: '14',
                            className: 'textField',
                            margin: "none",
                            variant: "outlined",
                        },
                    },
                    {
                        name: 'Alcím, egyéb cím',
                        tagNumber: '245',
                        subfieldCode: 'b',
                        size: 12,
                        type: 'text',
                        inputAttrs: {
                            name: '15',
                            className: 'textField',
                            margin: "none",
                            variant: "outlined",
                        },
                    },
                    {
                        name: 'Szerzőségi közlés',
                        tagNumber: '245',
                        subfieldCode: 'c',
                        size: 12,
                        type: 'text',
                        inputAttrs: {
                            name: '16',
                            className: 'textField',
                            margin: "none",
                            variant: "outlined",
                        },
                    },
                ]
            },
            {
                repeatable: true,
                helps: [
                    {
                        name: 'Szabályok',
                        value: 'a (szignált) kéziratos possessorbejgyzés szó szerint<br/><br/>a tulajdonos nevét ‘További személynév’, illetve ‘Intézménynév’ mezőben egységesíteni kell (Possessor) megjegyzéssel'
                    },
                    {
                        name: 'Példák',
                        value: 'Ad usum Matthiae Nagy Concionatoris Conventus Minorum Szegediensis<br/><br/>Bibliothecae Szegediensis Scholarum Piarum 1798.<br/><br/>Áll. Polgári Isk. (pecsét)'
                    }
                ],
                inputFields: [
                    {
                        name: 'Tulajdonosi bejegyzés',
                        tagNumber: '561',
                        subfieldCode: 'a',
                        size: 12,
                        type: 'text',
                        inputAttrs: {
                            name: '17',
                            className: 'textField',
                            margin: "none",
                            variant: "outlined",
                        },
                    },
                ]
            },
            {
                repeatable: true,
                helps: [],
                inputFields: [
                    {
                        name: 'Kiadás',
                        tagNumber: '250',
                        subfieldCode: 'a',
                        size: 12,
                        type: 'text',
                        inputAttrs: {
                            name: '40',
                            className: 'textField',
                            margin: "none",
                            variant: "outlined",
                        },
                    },
                ]
            },
            {
                name: 'Kiadó',
                repeatable: true,
                helps: [
                    {
                        name: 'Kiadás helye',
                        value: '<b>Szabálok</b></br>a kiadás földrajzi helye</br>ismételhető</br>[a mai névalak] a dokumentumban szereplő névalak</br>adatok hiányában: sine loco</br></br><b>Példák</b></br>[Leipzig] Leibzig</br>[Wien] Bechbe</br>[Venezia] Venetiis</br>sine loco'
                    },
                    {
                        name: 'Kiadó',
                        value: '<b>Szabálok</b></br>kiadó/nyomdász neve</br>ismételhető</br>a dokumentumban szereplő névalak + [opcionális egységesített névalak]</br>adatok hiányában: sine typ.</br></br><b>Példák</b></br>Verlegt von Andreas Zeidlern</br>apvd Baretivm Baretivm [Baretiana, officina.]</br>Henricus Gran pro Johanne Rymman [Gran, Heinrich; Rynman, Johann]</br>apud R. et. J. Wetstenios</br>sine typ.'
                    },
                    {
                        name: 'Kiadási év',
                        value: '<b>Szabálok</b></br>a kiadás ideje</br>Ismételhető</br>a dokumentumban szereplő forma [arab számmal írt évszám]</br>bizonytalan kiadási év esetén kérdőjellel: (?)</br>adatok hiányában: sine anno<br/><br/><b>Példák</b><br/>1700(?)<br/>[15??]<br/>MCCCCLXXXVII die aprilis septimo [1487]<br/>[1497-1500]<br/>sine anno'
                    }
                ],
                inputFields: [
                    {
                        name: 'Kiadás helye',
                        tagNumber: '260',
                        subfieldCode: 'a',
                        size: 12,
                        type: 'text',
                        inputAttrs: {
                            name: '18',
                            className: 'textField',
                            margin: "none",
                            variant: "outlined",
                        },
                    },
                    {
                        name: 'Kiadó',
                        tagNumber: '260',
                        subfieldCode: 'b',
                        size: 12,
                        type: 'text',
                        inputAttrs: {
                            name: '19',
                            className: 'textField',
                            margin: "none",
                            variant: "outlined",
                        },
                    },
                    {
                        name: 'Kiadási év',
                        tagNumber: '260',
                        subfieldCode: 'c',
                        size: 12,
                        type: 'text',
                        inputAttrs: {
                            name: '20',
                            className: 'textField',
                            margin: "none",
                            variant: "outlined",
                        },
                    },
                    {
                        name: 'Nyomda helye',
                        tagNumber: '260',
                        subfieldCode: 'e',
                        size: 12,
                        type: 'text',
                        inputAttrs: {
                            name: '21',
                            className: 'textField',
                            margin: "none",
                            variant: "outlined",
                        },
                    },
                ]
            },
            {
                name: 'Terjedelmi adatok',
                repeatable: true,
                helps: [
                    {
                        name: 'Szabályok',
                        value: 'sorrend: oldal-,v. foliószám, illusztráció, méret<br/><br/>az oldalszámokat a teljes kiadás alapján kell leírni, a hiányokat a ‘Megjegyzés a példány állapotáról’ mezőben kell felsorolni<br/><br/>a fol. a számozatlan, esetleg kihajtható, általában csak egyik oldalon nyomtatott lapokra vonatkozik<br/><br/>a számozatlan oldalak mennyiségét aszerint adjuk meg, amilyen kötet meglévő számozása (ha van számozott oldal, az oldalszámot, ha a foliók számozottak, akkor foliószámot adunk meg szögletes zárójelben)'
                    },
                    {
                        name: 'Példák',
                        value: '$a[81], XVIII, 572, [12] p., 4 fol. :$bmetszetek ;$c12o'
                    }
                ],
                inputFields: [
                    {
                        name: 'Oldalszám',
                        tagNumber: '300',
                        subfieldCode: 'a',
                        size: 12,
                        type: 'text',
                        inputAttrs: {
                            name: '22',
                            className: 'textField',
                            margin: "none",
                            variant: "outlined",
                        },
                    },
                    {
                        name: 'Méret (cm)',
                        tagNumber: '300',
                        subfieldCode: 'c',
                        size: 12,
                        type: 'text',
                        inputAttrs: {
                            name: '23',
                            className: 'textField',
                            margin: "none",
                            variant: "outlined",
                        },
                    }
                ]
            },
            {
                repeatable: true,
                helps: [],
                inputFields: [
                    {
                        name: 'Általános megjegyzés, szabadon szövegezhető',
                        tagNumber: '500',
                        subfieldCode: 'a',
                        size: 12,
                        type: 'text',
                        inputAttrs: {
                            name: '24',
                            className: 'textField',
                            margin: "none",
                            variant: "outlined",
                        },
                    },
                ]
            },
            {
                repeatable: true,
                helps: [
                    {
                        name: 'Szabályok',
                        value: 'ha a kötet a címlapon szereplő művön/műveken kívül más műv(ek)et is tartalmaz, akkor annak/azoknak címét és szerzőségi közlését a megjegyzésben kell leírni.'
                    },
                    {
                        name: 'Példák',
                        value: 'Főcím: Prognoma, sive Praesagium Mehemetanorum, primum de Christianorum calamitatibus, deinde de suae gentis interitu, ex Persica lingua in Latinum sermonem conversum / auth. Bartholomaeo Georgieuits<br/>Tartalmi megjegyzés: A kötet utolsó négy levelén: Exhortatio contra Turcas ad illustrissimum Principem Maximilianum Archiducem Austriae Ferdinandi Regis Romanorum, Hungariae, Bohemiae etc. filium ... / Bartholomaeo Georgieuits<br/><br/><br/>főcím: Ioannes Frobenius studiosis<br/>Tartalmi megjegyzés: További szerzők és művek: Panegyricus Maximiano et Constantino augustis dictus.Pacatus Drepanus, Latinus. Panegyricus Theodosio aug. Rhomae dictus. Panegyricus Constantino augusto Constantii filio dictus.<br/>134-176. p.Tristia / Ovidius'
                    }
                ],
                inputFields: [
                    {
                        name: 'Tartalmi megjegyzés',
                        tagNumber: '505',
                        subfieldCode: 't',
                        size: 12,
                        type: 'text',
                        inputAttrs: {
                            name: '25',
                            className: 'textField',
                            margin: "none",
                            variant: "outlined",
                        },
                    },
                ]
            },
            {
                repeatable: true,
                helps: [],
                inputFields: [
                    {
                        name: 'Kötés anyaga',
                        tagNumber: '563',
                        subfieldCode: 'a',
                        size: 12,
                        type: 'text',
                        inputAttrs: {
                            name: '1000',
                            className: 'textField',
                            margin: "none",
                            variant: "outlined",
                        },
                    },
                ]
            },
            {
                repeatable: true,
                helps: [],
                inputFields: [
                    {
                        name: 'Kötés díszitése',
                        tagNumber: '563',
                        subfieldCode: 'a',
                        size: 12,
                        type: 'text',
                        inputAttrs: {
                            name: '1001',
                            className: 'textField',
                            margin: "none",
                            variant: "outlined",
                        },
                    },
                ]
            },
            {
                repeatable: true,
                helps: [
                    {
                        name: 'Szabály',
                        value: 'bármilyen, a kötésre vonatkozó információ'
                    },
                    {
                        name: 'Példák',
                        value: '18. századi, nagyszombati, aranyozott bőrkötés<br/><br/>Magyarországi, aranyozott rokokó kötés, az elő- és a háttábla keretén évszám: 1766'
                    }
                ],
                inputFields: [
                    {
                        name: 'Kötés egyéb jellemzői',
                        tagNumber: '563',
                        subfieldCode: 'a',
                        size: 12,
                        type: 'text',
                        inputAttrs: {
                            name: '1002',
                            className: 'textField',
                            margin: "none",
                            variant: "outlined",
                        },
                    },
                ]
            },
            {
                repeatable: true,
                helps: [],
                inputFields: [
                    {
                        name: 'Kötés ideje',
                        tagNumber: '563',
                        subfieldCode: 'a',
                        size: 12,
                        type: 'text',
                        inputAttrs: {
                            name: '1003',
                            className: 'textField',
                            margin: "none",
                            variant: "outlined",
                        },
                    },
                ]
            },
            {
                helps: [
                    {
                        name: 'Szabályok',
                        value: 'a mű létrehozásában részes, vagy a példány történetével kapcsolatba hozható intézmény (egységesített) neve</br></br>városnév, intézmény név'
                    },
                    {
                        name: 'Példák',
                        value: 'Kassa, Akadémiai ny. (typographia)</br></br>Kecskemét, ferencesek könyvtára (possessor)'
                    }
                ],
                inputFields: [
                    {
                        name: 'Intézmény azonosítás',
                        tagNumber: '710',
                        subfieldCode: 'a',
                        size: 12,
                        type: 'text',
                        inputAttrs: {
                            name: '30',
                            className: 'textField',
                            margin: "none",
                            variant: "outlined",
                        },
                    },
                ]
            },
            {
                repeatable: true,
                helps: [
                    {
                        name: 'Szabály',
                        value: 'a leírt példány elektronikus változatának helye, azonosítója (URL, URN, DOI)'
                    },
                    {
                        name: 'Példák',
                        value: 'http://www.sk-szeged.hu/skkepek/grunn/688e1194.jpg<br/>http://diglib.hab.de/wdb.php?dir=inkunabeln/92-7-1-theol-3'
                    }
                ],
                inputFields: [
                    {
                        name: 'Elektronikus elérés és lelőhely',
                        tagNumber: '856',
                        subfieldCode: 'u',
                        size: 12,
                        type: 'text',
                        inputAttrs: {
                            name: '31',
                            className: 'textField',
                            margin: "none",
                            variant: "outlined",
                        },
                    },
                ]
            },
            {
                name: 'Példányadatok',
                helps: [
                    {
                        name: 'Szabályok',
                        value: 'a példány jelzete<br/>minden példány jelzete külön mezőbe kerüljön!<br/>jelzet nélküli példány esetén: sine sign.'
                    },
                    {
                        name: 'Példák',
                        value: 'K51<br/>Cc 52920<br/>RA 1465<br/>18.784<br/>sine sign.'
                    }
                ],
                repeatable: true,
                inputFields: [
                    {
                        name: 'Jelzet ',
                        tagNumber: '949',
                        subfieldCode: 'a',
                        size: 12,
                        type: 'text',
                        inputAttrs: {
                            name: '32',
                            className: 'textField',
                            margin: "none",
                            variant: "outlined",
                        },
                    },
                    {
                        name: 'Helyrajzi szám',
                        tagNumber: '949',
                        subfieldCode: 'j',
                        size: 12,
                        type: 'text',
                        inputAttrs: {
                            name: '33',
                            className: 'textField',
                            margin: "none",
                            variant: "outlined",
                        },
                    },
                    {
                        name: 'Egyedi példányazonosító',
                        tagNumber: '949',
                        subfieldCode: 'i',
                        size: 12,
                        type: 'text',
                        inputAttrs: {
                            name: '34',
                            className: 'textField',
                            margin: "none",
                            variant: "outlined",
                        },
                    },
                    {
                        name: 'Lelőhely',
                        tagNumber: '850',
                        subfieldCode: 'a',
                        size: 12,
                        type: 'text',
                        inputAttrs: {
                            name: '35',
                            className: 'textField',
                            margin: "none",
                            variant: "outlined",
                        },
                    },
                    {
                        name: 'Leltári szám',
                        tagNumber: '949',
                        subfieldCode: 'n',
                        size: 12,
                        type: 'text',
                        inputAttrs: {
                            name: '36',
                            className: 'textField',
                            margin: "none",
                            variant: "outlined",
                        },
                    },
                    {
                        name: 'Példánytípus',
                        tagNumber: '949',
                        subfieldCode: 't',
                        size: 12,
                        type: 'text',
                        inputAttrs: {
                            name: '37',
                            className: 'textField',
                            margin: "none",
                            variant: "outlined",
                        },
                    },
                    {
                        name: 'Vonalkód',
                        tagNumber: '949',
                        subfieldCode: 'z',
                        size: 12,
                        type: 'text',
                        inputAttrs: {
                            name: '38',
                            className: 'textField',
                            margin: "none",
                            variant: "outlined",
                        },
                    },

                ]
            }
        ]
    )

    const [formValues, setFormValues] = useState({
        1: '',
        2: '',
        3: '',
        4: '',
        5: '',
        6: '',
        7: '',
        8: '',
        9: '',
        10: '',
        11: '',
        12: '',
        13: '',
        14: '',
        15: '',
        16: '',
        17: '',
        18: '',
        19: '',
        20: '',
        21: '',
        22: '',
        23: '',
        24: '',
        25: '',
        1000: '',
        1001: '',
        1002: '',
        1003: '',
        30: '',
        31: '',
        32: '',
        33: '',
        34: '',
        35: '',
        36: '',
        37: '',
        38: '',
        40: '',
        100: ''
    });

    const [snackbarMessage, setSnackbarMessage] = useState(null);

    const [openSnackModal, setOpenSnackModal] = useState({
        open: false,
        variant: null
    });

    const closeErrorSnackbarHandler = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpenSnackModal({ ...openSnackModal, open: false });
    }

    useEffect(() => {
        setLanguageOptions();
        setCountryOptions();
        setLibraryOptions();
    }, [])

    const setLanguageOptions = useCallback(() => {
        const languageList = data.allLanguageMappingCsv.nodes.map(language => {
            return {
                value: language.key,
                label: language.hun
            };
        })
        languageList.splice(0, 0, { value: '', label: 'Válasszon' })
        const tempFormData = [...formData];
        tempFormData[1].inputFields[2].options = languageList
        setFormData(tempFormData);
    }, [])

    const setCountryOptions = useCallback(() => {
        const countryList = data.allCountryMappingCsv.nodes.map(country => {
            return {
                value: country.key,
                label: country.hun
            };
        })
        countryList.splice(0, 0, { value: '', label: 'Válasszon' })
        const tempFormData = [...formData];
        tempFormData[1].inputFields[1].options = countryList
        setFormData(tempFormData);
    }, [])

    const setLibraryOptions = useCallback(() => {
        const libraryList = data.allOdrKonyvtarkodListaCsv.nodes.map(library => {
            return {
                value: library.code,
                label: library.name + ' (' + library.code + ')'
            };
        })
        libraryList.splice(0, 0, { value: '', label: 'Válasszon' })
        const tempFormData = [...formData];
        tempFormData[3].inputFields[0].options = libraryList
        setFormData(tempFormData);
    }, [])

    const inputChangedHandler = (event) => {
        setFormValues({ ...formValues, [event.target.name]: event.target.value });
    }


    const duplicateSectionHandler = (receivedSection) => {
        const indexOfReceivedSection = formData.indexOf(receivedSection);
        const newFormData = [];
        for (let i = 0; i <= indexOfReceivedSection; i++) {
            newFormData.push(formData[i])
        }
        const newReceivedSection = { ...receivedSection };
        const newFormValues = { ...formValues };
        const newReceivedSectionInputs = newReceivedSection.inputFields.map(inputField => {
            const randomName = uuidv4()
            const tempInputfield = { ...inputField };
            const tempInputFieldAttrs = { ...tempInputfield.inputAttrs }
            tempInputFieldAttrs.name = randomName;
            tempInputfield.inputAttrs = tempInputFieldAttrs;
            newFormValues[randomName] = '';
            return tempInputfield
        })
        newReceivedSection.inputFields = newReceivedSectionInputs;
        newReceivedSection.deletable = true;
        newFormData.push(newReceivedSection)

        const arraySecondHalf = [];
        for (let i = formData.length - 1; i > indexOfReceivedSection; i--) {
            arraySecondHalf.push(formData[i])
        }

        for (let i = arraySecondHalf.length - 1; i >= 0; i--) {
            newFormData.push(arraySecondHalf[i])
        }
        setFormData(newFormData);
        setFormValues(newFormValues);
    }

    const deleteSectionHandler = receivedSection => {
        const indexOfReceivedSection = formData.indexOf(receivedSection);
        const tempFormData = [...formData];
        tempFormData.splice(indexOfReceivedSection, 1);
        setFormData(tempFormData);
    }

    const resetFormToInitial = () => {
        const clearedFormValues = {};
        Object.keys(formValues).map(value => {
            if (value.length < 4)
                clearedFormValues[value] = '';
        });
        setFormValues(clearedFormValues)
        const tempFormData = formData.filter(section => !section.deletable);
        setFormData(tempFormData)
    }

    const formValidation = () => {

        if (formValues[1].trim() === '')
            return false

        if (formValues[14].trim() === '')
            return false

        if (formValues[5].trim() === '')
            return false

        return true
    }

    const formSubmitHandler = (e) => {
        e.preventDefault();
        if (formValidation()) {
            let marcObject = {
                leader: "00000nam a2200000 i 4500",
                controlfields: [
                    {
                        tag: "008",
                        value: "920406s1992    hu |||||||||||||||||hun d"
                    }
                ],
                datafields: [
                    {
                        tag: "040",
                        indicator1: " ",
                        indicator2: " ",
                        subfields: [
                            {
                                code: "a",
                                value: " "
                            }
                        ]
                    },
                    {
                        tag: "041",
                        indicator1: " ",
                        indicator2: " ",
                        subfields: [
                            {
                                code: "a",
                                value: " "
                            }
                        ]
                    },
                    {
                        tag: "100",
                        indicator1: " ",
                        indicator2: " ",
                        subfields: [
                            {
                                code: "a",
                                value: " "
                            },
                            {
                                code: "b",
                                value: " "
                            },
                            {
                                code: "c",
                                value: " "
                            },
                            {
                                code: "d",
                                value: " "
                            }
                        ]
                    },
                    {
                        tag: "130",
                        indicator1: " ",
                        indicator2: " ",
                        subfields: [
                            {
                                code: "a",
                                value: " "
                            }
                        ]
                    },
                    {
                        tag: "240",
                        indicator1: " ",
                        indicator2: " ",
                        subfields: [
                            {
                                code: "a",
                                value: " "
                            }
                        ]
                    },
                    {
                        tag: "245",
                        indicator1: " ",
                        indicator2: " ",
                        subfields: [
                            {
                                code: "a",
                                value: " "
                            },
                            {
                                code: "b",
                                value: " "
                            },
                            {
                                code: "c",
                                value: " "
                            }
                        ]
                    },
                    {
                        tag: "250",
                        indicator1: " ",
                        indicator2: " ",
                        subfields: [
                            {
                                code: "a",
                                value: " "
                            }
                        ]
                    },
                    {
                        tag: "260",
                        indicator1: " ",
                        indicator2: " ",
                        subfields: [
                            {
                                code: "a",
                                value: " "
                            },
                            {
                                code: "b",
                                value: " "
                            },
                            {
                                code: "c",
                                value: " "
                            },
                            {
                                code: "e",
                                value: " "
                            }
                        ]
                    },
                    {
                        tag: "300",
                        indicator1: " ",
                        indicator2: " ",
                        subfields: [
                            {
                                code: "a",
                                value: " "
                            },
                            {
                                code: "c",
                                value: " "
                            }

                        ]
                    },
                    {
                        tag: "500",
                        indicator1: " ",
                        indicator2: " ",
                        subfields: [
                            {
                                code: "a",
                                value: " "
                            }
                        ]
                    },
                    {
                        tag: "505",
                        indicator1: " ",
                        indicator2: " ",
                        subfields: [
                            {
                                code: "t",
                                value: " "
                            }
                        ]
                    },
                    {
                        tag: "561",
                        indicator1: " ",
                        indicator2: " ",
                        subfields: [
                            {
                                code: "a",
                                value: " "
                            }
                        ]
                    },
                    {
                        tag: "563",
                        indicator1: " ",
                        indicator2: " ",
                        subfields: [
                            {
                                code: "a",
                                value: " "
                            }
                        ]
                    },
                    {
                        tag: "700",
                        indicator1: " ",
                        indicator2: " ",
                        subfields: [
                            {
                                code: "a",
                                value: " "
                            },
                            {
                                code: "b",
                                value: " "
                            },
                            {
                                code: "c",
                                value: " "
                            },
                            {
                                code: "d",
                                value: " "
                            }
                        ]
                    },
                    {
                        tag: "710",
                        indicator1: " ",
                        indicator2: " ",
                        subfields: [
                            {
                                code: "a",
                                value: " "
                            }
                        ]
                    },
                    {
                        tag: "850",
                        indicator1: " ",
                        indicator2: " ",
                        subfields: [
                            {
                                code: "a",
                                value: " "
                            }
                        ]
                    },
                    {
                        tag: "856",
                        indicator1: " ",
                        indicator2: " ",
                        subfields: [
                            {
                                code: "u",
                                value: " "
                            }
                        ]
                    },
                    {
                        tag: "949",
                        indicator1: " ",
                        indicator2: " ",
                        subfields: [
                            {
                                code: "a",
                                value: " "
                            },
                            {
                                code: "i",
                                value: " "
                            },
                            {
                                code: "j",
                                value: " "
                            },
                            {
                                code: "n",
                                value: " "
                            },
                            {
                                code: "t",
                                value: " "
                            },
                            {
                                code: "z",
                                value: " "
                            }
                        ]
                    }
                ]
            }

            let timeStamp = new Date();
            timeStamp = timeStamp.toLocaleString();
            let correctTimeFormat = '';
            for (let i = 0; i < timeStamp.length; i++) {
                if (timeStamp[i] !== '.' && timeStamp[i] !== ':' && timeStamp[i] !== ' ')
                    correctTimeFormat += timeStamp[i];
            }
            correctTimeFormat = correctTimeFormat + '.0';
            // marcObject.controlfields[0].value = correctTimeFormat;
            let dateStamp = new Date();
            dateStamp = dateStamp.toLocaleDateString();
            let correctDateFormat = '';
            for (let i = 2; i < dateStamp.length; i++) {
                if (dateStamp[i] !== '.' && timeStamp[i] !== ' ')
                    correctDateFormat += dateStamp[i]
            }
            let tag008value = correctDateFormat + 's' + formValues[2];
            tag008value += '    ';
            tag008value += formValues[3] + ' |||||||||||||||||' + formValues[4] + ' d';
            marcObject.controlfields[0].value = tag008value;

            formData.map(section => {
                section.inputFields.map(inputField => {
                    if (inputField.name === 'Dokumentum típusa') {
                        let newLeader = '';
                        switch (formValues[1]) {
                            case 'nyelvi anyag': newLeader = marcObject.leader.replace('a', 'a'); break;
                            case 'lejegyzett zene': newLeader = marcObject.leader.replace('a', 'c'); break;
                            case 'kéziratos zene': newLeader = marcObject.leader.replace('a', 'd'); break;
                            case 'kartográfiai anyag': newLeader = marcObject.leader.replace('a', 'e'); break;
                            case 'kéziratos kartográfiai anyag': newLeader = marcObject.leader.replace('a', 'f'); break;
                            case 'kivetített médium': newLeader = marcObject.leader.replace('a', 'g'); break;
                            case 'nem zenei hangfelvétel': newLeader = marcObject.leader.replace('a', 'i'); break;
                            case 'zenei hangfelvétel': newLeader = marcObject.leader.replace('a', 'j'); break;
                            case 'kétdimenziós nem kivetíthető grafika': newLeader = marcObject.leader.replace('a', 'k'); break;
                            case 'számítógép fájl': newLeader = marcObject.leader.replace('a', 'm'); break;
                            case 'készlet': newLeader = marcObject.leader.replace('a', 'o'); break;
                            case 'vegyes anyag': newLeader = marcObject.leader.replace('a', 'p'); break;
                            case 'háromdimenziós alkotások vagy természeti tárgyak': newLeader = marcObject.leader.replace('a', 'r'); break;
                            case 'kéziratos nyelvi anyag': newLeader = marcObject.leader.replace('a', 't'); break;
                        }
                        marcObject.leader = newLeader;
                        return null;
                    }
                    if (inputField.name === 'Bibliográfiai szint') {
                        if (formValues[100].trim() !== '') {
                            let newLeader = '';
                            if (formValues[1] !== 'számítógép fájl') {
                                switch (formValues[100]) {
                                    case 'a': newLeader = marcObject.leader.replace('m', 'a'); break;
                                    case 'b': newLeader = marcObject.leader.replace('m', 'b'); break;
                                    case 'c': newLeader = marcObject.leader.replace('m', 'c'); break;
                                    case 'd': newLeader = marcObject.leader.replace('m', 'd'); break;
                                    case 'i': newLeader = marcObject.leader.replace('m', 'i'); break;
                                    case 'm': newLeader = marcObject.leader.replace('m', 'm'); break;
                                    case 's': newLeader = marcObject.leader.replace('m', 's'); break;
                                }
                            }
                            else {
                                switch (formValues[100]) {
                                    case 'a': newLeader = marcObject.leader.replace('nmm', 'nma'); break;
                                    case 'b': newLeader = marcObject.leader.replace('nmm', 'nmb'); break;
                                    case 'c': newLeader = marcObject.leader.replace('nmm', 'nmc'); break;
                                    case 'd': newLeader = marcObject.leader.replace('nmm', 'nmd'); break;
                                    case 'i': newLeader = marcObject.leader.replace('nmm', 'nmi'); break;
                                    case 'm': newLeader = marcObject.leader.replace('nmm', 'nmm'); break;
                                    case 's': newLeader = marcObject.leader.replace('nmm', 'nms'); break;
                                }
                            }
                            marcObject.leader = newLeader;
                        }
                        return null;
                    }
                    if (inputField.inputAttrs.name.length<4 && inputField.tagNumber) {
                        marcObject.datafields = setSubfieldValue(inputField.tagNumber, inputField.subfieldCode, formValues[parseInt(inputField.inputAttrs.name)], marcObject.datafields);
                    }
                })
                if (section.inputFields[0].inputAttrs.name.length>3) {
                    const tempDataFields = setDuplicatedSubfieldValues(Object.assign({}, section), marcObject.datafields.slice());
                    marcObject.datafields = tempDataFields;
                }
            })
            if (marcObject.datafields[2].subfields[0].value.trim() === '') {
                marcObject.datafields[3].subfields[0].value = marcObject.datafields[5].subfields[0].value
            }
            else {
                marcObject.datafields[4].subfields[0].value = marcObject.datafields[5].subfields[0].value
            }


            marcObject.datafields.map((dataField, index) => {
                marcObject.datafields[index].subfields = dataField.subfields.filter(subfield => subfield.value.trim() !== '');
            })

            marcObject.datafields = marcObject.datafields.filter(dataField => dataField.subfields.length > 0)

            console.log(marcObject);
            //MUTATION
            sendDocument({
                variables: {
                    userName: props.userName,
                    body: JSON.stringify(marcObject)
                }
            }).then(res => {
                console.log('res')
                console.log(res.data)
                if (res.data) {
                    setSnackbarMessage('Dokumentum sikeresen beküldve!')
                    setOpenSnackModal({ ...openSnackModal, open: true, variant: 'success' });
                    document.getElementById("bejelento_form").reset();
                    window.scrollTo(0, 0);
                    resetFormToInitial();
                }
            }).catch(err => {
                if (err.graphQLErrors[0]) {
                    if (err.graphQLErrors[0].extensions.code === 'INTERNAL_SERVER_ERROR' && err.message.includes('internal server error')) {
                        setSnackbarMessage('Az oldal jelenleg nem elérhető, kérjük próbálja meg később.')
                        setOpenSnackModal({ open: true, variant: 'error' })
                    }
                    else if (err.graphQLErrors[0].extensions.code === 'INTERNAL_SERVER_ERROR' && err.message.includes('Too many requests')) {
                        setSnackbarMessage('Ön elérte az egy percen belül indítható maximum kérések számát, kérjük próbálkozzon újra egy perc múlva.')
                        setOpenSnackModal({ open: true, variant: 'error' })
                    }
                    else if (err.graphQLErrors[0].extensions.code === 'FORBIDDEN' && err.message.includes('invalid permission')) {
                        setSnackbarMessage('Önnek nincs jogosultsága az adott oldal eléréséhez, kérjük próbálja meg később vagy forduljon az oldal üzemeltetőjéhez.')
                        setOpenSnackModal({ open: true, variant: 'error' })
                    }
                }
                else {
                    setSnackbarMessage('Hálózati hiba')
                    setOpenSnackModal({ open: true, variant: 'error' })
                }
                console.log('err')
                console.log(err.graphQLErrors[0])
            })
        }
        else {
            setSnackbarMessage('A csillaggal jelölt mezők kitöltése kötelező!');
            setOpenSnackModal({ ...openSnackModal, open: true, variant: 'error' });
        }
    }

    const setSubfieldValue = (tagNumber, subfieldCode, inputValue, dataFields) => {
        const tempDataFields = [...dataFields];
        const dataFieldIndex = tempDataFields.findIndex(dataField => (dataField.tag === tagNumber));

        if (String(inputValue).trim() !== '' && dataFieldIndex >= 0) {
            const tempDataField = { ...dataFields[dataFieldIndex] };
            const tempDataFieldSubfields = [...tempDataField.subfields]
            for (let i = 0; i < tempDataFieldSubfields.length; i++) {

                if (tempDataFieldSubfields[i].code === subfieldCode) {
                    tempDataFieldSubfields[i].value = inputValue;
                }
            }

            tempDataField.subfields = tempDataFieldSubfields;
            tempDataFields[dataFieldIndex] = tempDataField;
            return tempDataFields;
        }
        return dataFields;
    }

    const setDuplicatedSubfieldValues = (section, dataFields) => {
        let tempSubfields = [];
        let dataFieldIndex = null;

        for (let i = 0; i < dataFields.length; i++) {
            for (let k = 0; k < section.inputFields.length; k++) {
                if (dataFields[i].tag === section.inputFields[k].tagNumber) {
                    dataFieldIndex = i;
                }
            }
        }

        const tempDataFields = dataFields.slice();
        const tempDataField = Object.assign({}, tempDataFields[dataFieldIndex]);
        tempDataFields.splice(dataFieldIndex + 1, 0, tempDataField);


        tempSubfields = tempDataFields[dataFieldIndex].subfields.map(subfield => {
            for (let i = 0; i < section.inputFields.length; i++) {
                if (section.inputFields[i].subfieldCode === subfield.code) {
                    const tempSubfield = Object.assign({}, subfield);
                    tempSubfield.value = formValues[section.inputFields[i].inputAttrs.name];
                    return tempSubfield
                }
            }
            return subfield
        })

        tempDataFields[dataFieldIndex + 1].subfields = tempSubfields
        return tempDataFields;
    }


    const formDisplay = formData.map((section) => {
        const sectionInputs = section.inputFields.map(inputField => {
            switch (inputField.type) {
                case 'select':
                    return (
                        <Grid
                            key={inputField.inputAttrs.name}
                            item
                            md={inputField.size}
                            xs={12}
                            className={notifyStyles.subDataBlock}
                        >
                            {inputField.name ? <span className={notifyStyles.lineSubLabel}>{inputField.name}</span> : null}
                            <select
                                {...inputField.inputAttrs}
                                onChange={(e) => inputChangedHandler(e)}
                            >
                                {inputField.options.map(option => <option value={option.value} key={option.value}>{option.label}</option>)}
                            </select>
                        </Grid >
                    )

                default: return (
                    <Grid
                        key={inputField.inputAttrs.name}
                        item
                        md={inputField.size}
                        xs={12}
                        className={notifyStyles.subDataBlock}
                    >
                        {inputField.name ? <span className={notifyStyles.lineSubLabel}>{inputField.name}</span> : null}
                        <div className={'textFieldContainer'}>
                            <TextField
                                type={inputField.type}
                                {...inputField.inputAttrs}
                                onBlur={(e) => inputChangedHandler(e)}
                            />
                        </div>
                    </Grid>
                )
            }

        })




        let helpModal = null;
        if (section.helps.length > 0) {

            const helpModalInputs = section.helps.map(help => (
                <Grid container className={notifyStyles.helpDialogLineTable} key={help.value}>
                    <Grid
                        container
                        item
                        md={3}
                        xs={12}
                        justify="center"
                        alignItems="center"
                    >
                        <span>{help.name}</span>
                    </Grid>
                    <Grid
                        item
                        md={9}
                    >
                        <span dangerouslySetInnerHTML={{ __html: help.value }}></span>
                    </Grid>
                </Grid>
            ))

            helpModal = (
                <HelpDialogSlide>
                    {helpModalInputs}
                </HelpDialogSlide>
            )
        }


        let iconsDisplay = null
        if (section.repeatable) {
            iconsDisplay = (
                <Grid
                    item
                    sm={2}
                    xs={12}
                    className={notifyStyles.dataBlock}
                >
                    <Button className={notifyStyles.addButton} onClick={() => duplicateSectionHandler(section)}><AddIcon /></Button>
                </Grid>
            )
            if (section.deletable) {
                iconsDisplay = (
                    <Grid
                        item
                        sm={2}
                        xs={12}
                        className={notifyStyles.dataBlock}
                    >
                        <Button className={notifyStyles.addButton} onClick={() => duplicateSectionHandler(section)}><AddIcon /></Button>
                        <Button style={{ marginLeft: '10px' }} className={notifyStyles.addButton} onClick={() => deleteSectionHandler(section)}><HighlightOffIcon /></Button>
                    </Grid>
                )
            }
        }

        return (
            <Grid container className={notifyStyles.notificationLine} key={section.inputFields[0].inputAttrs.name}>
                <Grid item sm={2} xs={12}>
                    <Grid
                        container
                        justify="flex-end"
                        alignItems="center"
                        className={notifyStyles.lineLabelContainer}
                    >
                        <span className={notifyStyles.lineLabel}>{section.name}</span>
                        {helpModal}
                    </Grid>
                </Grid>
                <Grid
                    item
                    sm={8}
                    xs={12}
                    className={notifyStyles.dataBlock}
                >
                    <div className={'selectorFieldContainer'}>
                        <Grid container>
                            {sectionInputs}
                        </Grid>
                    </div>
                </Grid>
                {iconsDisplay}
            </Grid>
        )
    })

    return (
        <React.Fragment>
            <Head title={'Bejelentő űrlap'} />
            <Layout>
                <BreadcrumbsLayout menuItems={[{ name: 'Főoldal', path: '/' }, { name: 'Bejelentés' }]}>
                    <div dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }}></div>
                    <Grid container className={notifyStyles.dataFields}>
                        <form onSubmit={formSubmitHandler} id='bejelento_form'>
                            {formDisplay}
                            <Grid container className={notifyStyles.notificationLine}>
                                <Grid container className={notifyStyles.dataBlockButton} justify="center" alignItems="center">
                                    <Button type='submit' className={notifyStyles.sendButton}>Beküldés</Button>
                                </Grid>
                            </Grid>
                        </form>
                    </Grid>
                </BreadcrumbsLayout>
            </Layout>

            <Snackbar
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left"
                }}
                open={openSnackModal.open}
                autoHideDuration={6000}
                onClose={closeErrorSnackbarHandler}
            >
                <ErrorSnackbar
                    onClose={closeErrorSnackbarHandler}
                    variant={openSnackModal.variant}
                    message={snackbarMessage}
                />
            </Snackbar>

        </React.Fragment>
    )
}

export default Bejelentes;
