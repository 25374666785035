import React, {useContext} from "react"

//CONTEXT
import {AuthContextState} from '../context/auth-context/auth-context';

//COMPONENTS
import Bejelentkezes from '../components/Auth/bejelentkezes';
import Bejelentes from '../components/Auth/restrictedPages/bejelentes';

const PrivateRoute = (props) => {
    const state=useContext(AuthContextState);

    if (state.accessToken.trim()==='') {
        return <Bejelentkezes />
    }
    return <Bejelentes userName={state.userName} />
}

export default PrivateRoute