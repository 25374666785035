import React from 'react';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';

import styles from './helpDialog.module.scss';

import {Help} from '@material-ui/icons';
import {Close} from '@material-ui/icons';

function SimpleDialog(props) {
  const { onClose, open } = props;

  function handleClose() {
    onClose(false);
  }

  return (
      <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open} maxWidth="md" minWidth="md" fullWidth className={styles.helpDialogBox}>
        <DialogTitle className={styles.helpDialogTitle}>
          <Help className={styles.helpDialogIcon} />
          Segítség
        </DialogTitle>
        <div className={styles.helpDialogContent}>
          {props.children}
        </div>
      </Dialog>
  );
}

SimpleDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default function SimpleDialogDemo(props) {
  const [open, setOpen] = React.useState(false);

  function handleClickOpen() {
    setOpen(true);
  }

  const handleClose = value => {
    setOpen(false);
  };

  return (
      <div>
        <Button variant="text" size="small" onClick={handleClickOpen} className={styles.helpDialogButton}>
          <Help className={styles.buttonIcon} onClick={handleClickOpen}/>
        </Button>
        <SimpleDialog
          open={open}
          onClose={handleClose}
        >
          <Button className={styles.closeButton} onClick={handleClose} >
            <Close  />
          </Button>
          {props.children}
        </SimpleDialog>

      </div>
  );
}
