import React from 'react';
import { Link } from 'gatsby';
import {
  Container,
  Grid
} from '@material-ui/core';

import NavigationBar from './Menubar/mainmenu';
import styles from './header.module.scss';


const Header = () => {
  return (
    <header className={styles.headerContainer}>
      <Container maxWidth="lg">
        <Grid container component="div" className={styles.headerWrapper} spacing={0} alignItems="center">
          <Grid
              item
              component="div"
              md={3}
              className={styles.logoSpace}
          >
            <Link to="/">
              <div className={styles.mkdnyLogo} />
            </Link>
          </Grid>
          <Grid
              item
              component="div"
              md={9}
              className={styles.menuSpace}
          >
            <NavigationBar/>
          </Grid>
        </Grid>
      </Container>
    </header>
  )
}

export default Header
