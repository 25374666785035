import React, { useState, useContext } from 'react';

//CSS
import {
    Button,
    TextField,
    Snackbar
} from '@material-ui/core';

import styles from '../../pages/index.module.scss';
import stylesBejelentkezes from './bejelentkezes.module.scss';

//HOC
import BasicLayout from '../../layouts/basic_layout';
import BreadcrumbsLayout from '../../layouts/breadcrumbs_layout';
import { setAccessToken } from './accessToken';

//COMPONENTS
import Head from '../head';
import ErrorSnackbar from '../ErrorSnackbar/ErrorSnackbar';

//CONTEXT
import { AuthContextDispatch } from '../../context/auth-context/auth-context';
import * as actionTypes from '../../context/actionTypes';

//GATSBY
import { useStaticQuery, graphql } from "gatsby"
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';


const LOGIN_USER = gql`
    mutation($username: String!, $password: String!){
    login(data: {username: $username, password: $password}){
        accessToken
        }
    }
`;

const Bejelentkezes = props => {

    const data = useStaticQuery(graphql`
            query{
            markdownRemark(frontmatter: {title: {eq: "Bejelentkezés"}}){
                frontmatter{
                title
                mainpage
                }
                html
            }
        }
    `)

    const dispatch = useContext(AuthContextDispatch)

    const [loginUser] = useMutation(LOGIN_USER);


    const [credentials, setCredentials] = useState({
        username: '',
        password: ''
    });

    const [errors, setErrors] = useState({
        username: {
            touched: false
        },
        password: {
            touched: false
        },
        serverValidateError: null
    });

    const [openError, setOpenError] = useState(false);

    const inputChangedHandler = e => {
        setCredentials({ ...credentials, [e.target.id]: e.target.value })
    }

    const closeErrorSnackbarHandler = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpenError(false);
    }

    const formSubmittedHandler = e => {
        e.preventDefault();
        setErrors({ ...errors, serverValidateError: null })
        loginUser({
            variables: {
                username: credentials.username,
                password: credentials.password
            }
        }).then(res => {
            console.log('res');
            console.log(res);
            dispatch({
                type: actionTypes.SET_ACCESS_TOKEN,
                accessToken: res.data.login.accessToken,
                userName: credentials.username
            })
            setAccessToken(res.data.login.accessToken);
            // navigate('/auth/bejelentes')
        }).catch(err => {
            console.log('err')
            console.log(err)
            console.log(err.graphQLErrors[0])
            if (err.graphQLErrors[0]) {
                if (err.graphQLErrors[0].extensions.code === 'BAD_USER_INPUT' && err.message.includes('bad credentials')) {
                    setErrors({ ...errors, serverValidateError: 'A megadott adatok nem megfelelőek.' });
                }
                else if (err.graphQLErrors[0].extensions.code === 'INTERNAL_SERVER_ERROR' && err.message.includes('internal server error')) {
                    setErrors({ ...errors, serverValidateError: 'Váratlan hiba történt, kérjük próbálja meg később a belépést.' });
                }
                else if (err.graphQLErrors[0].extensions.code === 'INTERNAL_SERVER_ERROR' && err.message.includes('Too many requests')) {
                    setErrors({ ...errors, serverValidateError: 'Ön elérte az egy percen belül indítható maximális bejelentkezési kísérlet számát, kérjük próbálkozzon újra egy perc múlva.' });
                }
            }
            else {
                setErrors({ ...errors, serverValidateError: 'Hálózati hiba.' })
            }
            setOpenError(true);
        })
    }


    const inputFields = {
        username: {
            name: 'Felhasználónév',
            id: 'username',
            type: 'text'
        },
        password: {
            name: 'Jelszó',
            id: 'password',
            type: 'password'
        }
    }

    const inputFieldsDisplay = Object.keys(inputFields).map(inputField => {
        let error = null;
        if (errors[inputField].touched && !credentials[inputField])
            error = <div style={{ color: 'red' }}>{inputFields[inputField].name} mezőt kötelező kitölteni!</div>;

        return (
            <React.Fragment key={inputField}>
                <label htmlFor={inputFields[inputField].id}>{inputFields[inputField].name}</label>
                <TextField
                    variant="outlined"
                    // label={inputFields[inputField].name}
                    type={inputFields[inputField].type}
                    id={inputFields[inputField].id}
                    // placeholder={inputFields[inputField].name}
                    value={credentials[inputField]}
                    onChange={inputChangedHandler}
                    fullWidth={true}
                    error={credentials[inputField] ? false : errors[inputField].touched}
                    onBlur={() => setErrors({
                        ...errors,
                        [inputField]: {
                            touched: true
                        }
                    })} />
                {error}
            </React.Fragment>
        )
    })

    return (
        <React.Fragment>
            <BasicLayout>
                <Head title={data.markdownRemark.frontmatter.title} />
                <BreadcrumbsLayout menuItems={[{ name: data.markdownRemark.frontmatter.mainpage, path: '/' }, { name: 'Bejelentés' }]}>
                    <div className={styles.textContentContainer}>
                        <h3>Bejelentés</h3>
                        <div className={stylesBejelentkezes.mdContainer} dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }}></div>
                        <div className={stylesBejelentkezes.container}>
                            <form onSubmit={formSubmittedHandler}>
                                {inputFieldsDisplay}
                                <div className={stylesBejelentkezes.submitButtonContainer}>
                                    <Button
                                        type='submit'
                                        className={stylesBejelentkezes.submitButton}

                                        disabled={credentials.username.trim() === '' || credentials.password.trim() === ''}>
                                        {data.markdownRemark.frontmatter.title}
                                    </Button>
                                </div>
                            </form>
                        </div>
                    </div>
                </BreadcrumbsLayout>
            </BasicLayout>
            <Snackbar
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left"
                }}
                open={openError}
                autoHideDuration={6000}
                onClose={closeErrorSnackbarHandler}
            >
                <ErrorSnackbar
                    onClose={closeErrorSnackbarHandler}
                    variant="error"
                    message={errors.serverValidateError}
                />
            </Snackbar>
        </React.Fragment>
    )
}
export default Bejelentkezes;
